import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { setLoggedUser } from "../../redux/actions";
import { redirect,callService,activeMenuOption,alertOption,pageLoadOption } from "../../Utils";
import { API_URL } from "../../constants/api";
import { PAGES_TITLE } from "../../constants";

const type = "stripe_setting";
const initialErrorState = {
  test: false,
  live: false,
  mode:false,
};
const initialMsgState = {
  test: "This field is mandatory",
  live: "This field is mandatory",
  mode: "This field is mandatory",
};

const initialState = {
  details:{
    test: "",
    live: "",
    mode: "",
  },
  created_at:"",
  id:"",
};
const Stripe = () => {
  let selectImage;
  const [formData, setFormData] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(initialErrorState);
  const [errorMsg, setErrorMsg] = useState(initialMsgState);
  const { details,id,created_at } = formData;
  const dispatch = useDispatch();

  useEffect(async() => {
    pageLoadOption.load(true);
    activeMenuOption.setCurrentmenu(type,PAGES_TITLE[type]);
    await getItemData();
  }, []);

  const handleChange = (e)=> {
    const { name, value } = e.target;
    setFormData(inputs => ({ ...inputs,details:{...inputs.details,[name]: value }}));
  };
  const getItemData = async()=>{
    //name, description, image, parent_id
    const response = await callService(`${API_URL.SETTING_API}${type}`,"GET");
    if(response.success !== undefined && response.success){
      const {item,url} = response.data;
      setFormData({details:item.details,id:item.id,created_at:item.created_at});
      console.log({details:item.details,id:item.id,created_at:item.created_at},"asdasd");
    }
    pageLoadOption.load(false);
  };
  const updateSetting = async(args) =>{
    pageLoadOption.load(true);
    let params = {...details,id,created_at};
    const response = await callService(`${API_URL.SETTING_API}${type}`,"PATCH",params,null,true);
    let alertParam = {show:true,message:response.message};
    if(response.success !== undefined && response.success){
      let { item } = response.data;
      setFormData(item);
      //set alert message info
      alertParam["type"] = "success";
    }else{
      alertParam["type"] = "danger";          
    }
    alertOption.setMsg(alertParam);
    setSubmitted(false);
    pageLoadOption.load(false);
  };
  const setFormError = (name,value)=> {
    setErrors(inputs => ({ ...inputs, [name]: value }));
  };
  const setFormErrorMsg = (name,value)=> {
    setErrorMsg(inputs => ({ ...inputs, [name]: value }));
  };
  const validateFrm = ()=> {
    let err = false;

    Object.keys(formData).map((key)=>{
      if(formData[key]==""){
        setFormError(key,true);
        err = true;
      }  
    });
    return !err;
  };

  const handleSubmit = async(e)=> {
    e.preventDefault();
    if (validateFrm()) {
      setSubmitted(true);
      await updateSetting();
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Stripe Setting</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Setting</h3>
              </div>
              <div className="box-body">
                <div className="x_content">
                  <form method="post" className="form-horizontal form-label-left" onSubmit={handleSubmit}>
                    <div className="box-body">
                      <div className="input required">
                        <div className={errors.test ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="first_name">Test Secret Key</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" name="test" value={details.test} placeholder="Test Secret Key" required="required" className="form-control col-md-7 col-xs-12" onChange={handleChange}/>
                            {errors.test && <span className="help-block">{errorMsg.test}.</span>}
                          </div>
                        </div>
                      </div>
                      <div className="input required">
                        <div className={errors.live ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="live">Live Secret Key</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" name="live" value={details.live} placeholder="Live Secret Key" required="required" className="form-control col-md-7 col-xs-12" onChange={handleChange}/>
                            {errors.live && <span className="help-block">{errorMsg.live}.</span>}
                          </div>
                        </div>
                      </div>
                      <div className="input required">
                        <div className={errors.mode ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="mode">Mode</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <select name="mode" required="required" className="form-control col-md-7 col-xs-12" onChange={handleChange}>
                              <option value="test" selected={ details.mode == "test" ? "selected" : "" }>SandBox</option>
                              <option value="live" selected={ details.mode == "live" ? "selected" : "" }>Live</option>
                            </select>
                            {errors.mode && <span className="help-block">{errorMsg.mode}.</span>}
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="box-footer">
                      <div className="form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12 col-md-offset-3">
                          <button className="btn btn-primary" type="submit">{submitted && (<>
                            <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                          </>)}Update</button>&nbsp;&nbsp;
                          <Link to="/dashboard">Cancel</Link>
                        </div>
                      </div>
                    </div>
                  </form>   
                </div> 
              </div>                   
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stripe;