import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authOption,redirect } from "../../../Utils";

const Header = ()=>{
  const user = useSelector(state => state.authentication.user);
  const logout =()=>{
    authOption.signout();
    redirect("/login");
  };
  return (
    <header className="main-header">
      <a href="#" className="logo">
        <span className="logo-mini"><b>RO</b></span>
        <span className="logo-lg"><b>Roshi</b></span>
      </a>
      <nav className="navbar navbar-static-top">
        <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
          <span className="sr-only">Toggle navigation</span>
        </a>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li className="dropdown user-menu user">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src={user.profile_image ? user.image_path + "/" + user.profile_image : "/img/user2-160x160.jpg"} className="user-image" alt="User" />
                <span className="hidden-xs">{user.first_name ? user.first_name + " " +user.last_name : user.user_name}</span>
              </a>
              <ul className="dropdown-menu right-logout-menu-custom">
                <li>
                  <Link to="/profile" className="loader-overlay"><i className="fa fa-cogs"></i> Profile Settings</Link>
                </li>
                <li>
                  <Link to="/change_password" className="loader-overlay"><i className="fa fa-edit"></i> Change Password</Link>
                </li>
                <div className="divider"></div>
                <li>
                  <a href="#" onClick={logout} className="loader-overlay"><i className="fa fa-sign-out"></i> Sign Out</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );	
};
export default Header;