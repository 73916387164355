import React from "react";
import { Link } from "react-router-dom";

const Footer = ()=>{
  return (
    <footer className="main-footer ">
      {/*<div className="pull-right hidden-xs">
                            <b>Version</b> 2.0
                        </div>*/}
      <strong>Copyright © {(new Date()).getFullYear()} <Link to="/dashboard">Roshi</Link></strong> All rights reserved.
    </footer>
  );	
};
export default Footer;