import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { activeMenuOption,callService,pageLoadOption,alertOption } from "../../Utils";
import { API_URL } from "../../constants/api";

const Message = () => {  
  const [pageLoad, setPageLoad] = useState(true);
  const [items, setItems] = useState([]);
  useEffect(async()=>{
    await fetchData();
  },[]);
  const setFalseAll = ()=>{
    setPageLoad(false);
    pageLoadOption.load(false);
  };
  const deleteItem = async({id,created})=>{
    let msg = "Are you sure to delete the item?";
    if(window.confirm(msg)){
      pageLoadOption.load(true);
      const response = await callService(`${API_URL.NOTIFICATION_API}${id}?create=${created}`,"DELETE");    
      if(response.success !== undefined && response.success){
        await fetchData();
        alertOption.setMsg({show:true,type:"success",message:response.message});
      }else{
        //set alert message info
        alertOption.setMsg({show:true,type:"error",message:response.message});
        setFalseAll();
      }
    }
  };
  const statusUpdate = async({id,created,status},type=0)=>{
    let msg = status ? "Are you sure to deactivate the item?" : "Are you sure to activate the item?";
    if(window.confirm(msg)){
      pageLoadOption.load(true);
      let url = `${API_URL.NOTIFICATION_UPDATE}${id}`;
      let method = "PATCH";
      let params = {created:created,status: status ? 0 : 1};
      const response = await callService(url,method,params);    
      if(response.success !== undefined && response.success){
        items.map(item=>{item.is_activate = !status; return item;});
        alertOption.setMsg({show:true,type:"success",message:response.message});
      }else{
        //set alert message info
        alertOption.setMsg({show:true,type:"error",message:response.message});
      }
      setFalseAll();
    }
  };
  const fetchData = async() =>{
    const response = await callService(API_URL.NOTIFICATION_LIST_API,"GET");
    ///console.log(response,"api call response ");
    if(response.success !== undefined && response.success){
      setItems(response.items);
    }
    setFalseAll();
  };
  const activeMenu = (menu) =>{
    activeMenuOption.setCurrentmenu(menu);
  };
  return (
    <>
      <section className="content-header">
        <h1>All Messages</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-black">
              <div className="box-body no-padding">
                <div className="nav-tabs-custom no-margin">                    
                  <div className="tab-content no-padding">
                    <div className="tab-pane active" id="listing">
                      <div className="table-responsive1">
                        <table className="table table-hover table-striped TblAlignedCentered no-margin">
                          <thead>
                            <tr>
                              <th width="8%"></th>
                              <th width="72%">Session </th>
                              <th width="15%">Unread Messages</th>
                              <th width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {pageLoad ? 
                              <tr>
                                <td colSpan="4" className="text-center">
                                  <i className="fa fa-spin fa-refresh"></i>
                                </td>
                              </tr> :
                              <>
                                {items.length>0  ?
                                  items.map((item,key)=>{
                                    return (
                                      <tr key={key}>
                                        <td>
                                          <img src="/img/user2-160x160.jpg" alt="image" className="img-circle" width="50px" height="50px" />
                                        </td>
                                        <td className="mid-vertical-align">
                                                George Barbas
                                        </td>
                                        <td className="text-center mid-vertical-align">
                                          <small type="button" className="label bg-yellow">0</small>  
                                        </td>
                                        <td className="mid-vertical-align">
                                          <Link to="message/read/1" className="btn btn-sm btn-primary loader-overlay">
                                            <i className="fa fa-envelope"></i> Read
                                          </Link>
                                        </td>
                                      </tr>
                                    );}) 
                                  :
                                  <tr>
                                    <td colSpan="4" className="text-center">No Record found.</td>
                                  </tr>
                                }
                              </>}
                          </tbody>
                        </table>
                      </div>            
                      <div className="box-footer clearfix">
                        <div className="text-center">
                          <div className="paginate-count pull-left">Total Records : {items.length}</div>
                        </div>
                        {/* <ul className="pagination pagination-sm no-margin pull-right">
                        <li><a href="#">«</a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">»</a></li>
                    </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Message;