import { Redirect } from "react-router-dom";
import store from "../redux/store";
import { setLoggedUser,logoutUser,setCurrentMenu,setAlertInfo,hideAlert,setPageLoad } from "../redux/actions";

export const authOption = {
  isAuthenticated: (store.getState().authentication && store.getState().authentication.loggedIn?true:false),
  authenticate(user) {
    store.dispatch(setLoggedUser({ user }));
    this.isAuthenticated = true;
    //setTimeout(user, 100)
  },
  signout() {
    activeMenuOption.setCurrentmenu();
    alertOption.hide();
    store.dispatch(logoutUser());
    //setTimeout(cb, 100)
  },
  getData(){
    return (this.isAuthenticated && store.getState().authentication && store.getState().authentication.user?store.getState().authentication.user:{});
  },
};

export const activeMenuOption = {
  activeMenu: (store.getState().activeMenu?store.getState().activeMenu.name:"home"),
  setCurrentmenu(menu="",title="") {
    store.dispatch(setCurrentMenu(menu));
    this.activeMenu = menu;
    if(title.length > 0){
      this.setPageTitle(title);
    }
  },
  setPageTitle(title="") {
    if(title.length > 0){
      document.title = title;
    }
  },
};

export const alertOption = {
  setMsg(info) {
    store.dispatch(setAlertInfo(info));
  },
  hide() {
    store.dispatch(hideAlert());
  },
};

export const pageLoadOption = {
  load(show) {
    store.dispatch(setPageLoad(show));
  },
};

export const redirect = (redirect="/",history=null)=>{
  if(history == null)
  {return <Redirect to={redirect} />;}
  else
  {history.push(redirect);}
};

export const capitalize = (str)=> {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
  {
    return index == 0 ? word.toUpperCase() : word.toLowerCase();
  }).replace(/\s+/g, "");
};

export const toTimeStamp = (strDate)=> {
  var datum = Date.parse(strDate);
  return datum/1000;
};

export const getCurrentTimeStamp = ()=>{
  return new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getTime();
};

export const getDate = (timestamp,date=true,time=true,monthName="long")=> {
  var d = (new Date(timestamp));
  // Hours part from the timestamp
  var hours = d.getHours();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // Minutes part from the timestamp
  var minutes = "0" + d.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + d.getSeconds();

  // Will display time in 10:30:23 format
  return (date ? d.getDate() + " "+(d.toLocaleString("default", { month: monthName }))+", "+d.getFullYear() : "" ) + (date && time ? " " : "" ) + (time ? hours + ":" + minutes.substr(-2) + " " + ampm : "" );
};

export const getTime = (timestamp)=>{
  var d = (new Date(timestamp));
  // Hours part from the timestamp
  var hours = d.getHours();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // Minutes part from the timestamp
  var minutes = "0" + d.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + d.getSeconds();

  // Will display time in 10:30:23 format
  return hours + ":" + minutes.substr(-2) + " " + ampm ; 
};

/**
 * Call service api 
 */
export const  callService = async(url,method="GET",params={},callBack=null,multipart=false,navigation=null) =>{
  let qry = [];
  let res,body;
  let headers = {  };
  if(multipart){
    //headers = { 'Content-Type' : 'multipart/form-data' };
    body = new FormData();
    // Assume "photo" is the name of the form field the server expects
    Object.keys(params).forEach(function(key) {
      body.append(`${key}`, params[key]);
    });
  }else{
    headers = { "Content-Type" : "application/x-www-form-urlencoded" };
    Object.keys(params).forEach(function(key) {
      qry.push(key+"="+params[key]);
    });
    body = qry.join("&");
  }
  if(store.getState().authentication && store.getState().authentication.loggedIn){
    headers["x-access-token"] = store.getState().authentication.user.token;
  }
  try {
    let serviceParams = {
      method: method, // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      //body:  body// body data type must match "Content-Type" header
    };
    if(method == "POST" || method == "PUT" || method == "PATCH"){
      serviceParams["body"] = body;
    }
    //console.log("callservice nooday part",body);
    const response = await fetch(url,serviceParams);
    res = response ? response.status && response.status === 204 ? "" : await response.json() : "";
    
  } catch (error) {
    res = {success:false,error:error};
  }
  if(!res.success){
    console.log( " Error " , res );
    if(res.token_error != undefined && res.token_error){
      console.log(" i m in logout function ");
      logout();
      return ;
    }
  }
  if(callBack!==null){
    callBack(res);
    return ;
  }else{
    return res;
  }
};
export const logout = (confirmationBox = false) => {
  if(confirmationBox){
    /*Alert.alert(
      'Warning',
      'Are sure you want to logout from app?',
      [
        {text: 'Logout', onPress: async() => {
          try {
            await removeAsyncData('user');
            await removeAsyncData('menu');
            await removeAsyncData("location");
            store.dispatch(messageChangeCount(0));
            store.dispatch(visitorChangeCount(0));
            navigation.navigate('Login');
          } catch (err) {
            console.log(`The error is: ${err}`)
          }
        }},
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        }
      ],
      {cancelable: false},
    );*/
  }else{
    authOption.signout();
    redirect("Login");
  }
};

export const objectToQueryString = (obj)=> {
  var str = [];
  for (var p in obj)
  {if (Object.prototype.hasOwnProperty.call(obj, p)) {
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
  }}
  return str.join("&");
};

export const getDateWithFormat = (value,format="Y-m-d")=>{
  let data= new Date(value);
  let month = data.getMonth() + 1;
  let day = data.getDate();
  let year = data.getFullYear();
  if(day<=9)
  {day = "0" + day;}
  if(month<10)
  {month = "0" + month;}

  let reDate = "";
  switch(format){
  case "Y-m-d":
  case "Y-M-D":
    reDate = year + "-" + month + "-" + day;
    break;
  default:
    reDate = year + "-" + month + "-" + day;
    break;
  }

  return reDate;
};