import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { activeMenuOption,callService,pageLoadOption,alertOption,getDate,objectToQueryString } from "../../Utils";
import { API_URL } from "../../constants/api";
import { ROLE,PAGES_TITLE } from "../../constants";

const Notification  = (props) => {  
  const [pageLoad, setPageLoad] = useState(true);
  const [items, setItems] = useState([]);
  const [pagination,setPagination] = useState({pageCount:0,initialPage:0});

  useEffect(async()=>{
    await fetchData();
    activeMenuOption.setPageTitle(PAGES_TITLE["notification"]);
  },[]);
  const setFalseAll = ()=>{
    setPageLoad(false);
    pageLoadOption.load(false);
  };
  const deleteItem = async({id,created})=>{
    let msg = "Are you sure to delete the item?";
    if(window.confirm(msg)){
      pageLoadOption.load(true);
      const response = await callService(`${API_URL.NOTIFICATION_API}${id}?create=${created}`,"DELETE");    
      if(response.success !== undefined && response.success){
        await fetchData();
        alertOption.setMsg({show:true,type:"success",message:response.message});
      }else{
        //set alert message info
        alertOption.setMsg({show:true,type:"error",message:response.message});
        setFalseAll();
      }
    }
  };
  const statusUpdate = async({id,created,status,publish},type=0)=>{
    let msg = publish!==undefined ? "Are you sure to publish this notification?" : (status ? "Are you sure to deactivate the item?" : "Are you sure to activate the item?");
    if(window.confirm(msg)){
      pageLoadOption.load(true);
      let url = `${API_URL.NOTIFICATION_UPDATE}${id}`;
      let method = "PATCH";
      let params = {created:created};
      if(publish!== undefined){
        params["publish"] = 1;
      }else{
        params["status"] = status ? 0 : 1;
      }
      const response = await callService(url,method,params);    
      if(response.success !== undefined && response.success){
        if(publish!== undefined){
          items.map(item=>{ if(item.id==id){item.is_publish = 1;} return item;});
        }else{
          items.map(item=>{ if(item.id==id){item.is_activate = !status; } return item;});
        }
        alertOption.setMsg({show:true,type:"success",message:response.message});
      }else{
        //set alert message info
        alertOption.setMsg({show:true,type:"error",message:response.message});
      }
      setFalseAll();
    }
  };
  const fetchData = async(queryString = {}) =>{
    pageLoadOption.load(true);
    setPageLoad(true);
    let url = API_URL.NOTIFICATION_LIST_API ;
    let qryString = {};
    if(Object.keys(queryString).length>0){
      qryString = objectToQueryString(queryString);
      url += "?"+qryString;
    }
    updateHistory(qryString);
    const response = await callService(url,"GET");
    ///console.log(response,"api call response ");
    if(response.success !== undefined && response.success){
      const {items,Pagination} = response.data;
      setItems(items);
      setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)});
    }else{
      alertOption.setMsg({show:true,type:"error",message:response.message});
    }
    setFalseAll();
  };
  const updateHistory = (queryString)=>{
    props.history.push({
      pathname: props.match.url,
      search: Object.keys(queryString).length>0?"?" + queryString:"",
    });
  };
  const nextPageRecord = async(event)=>{
    if(event.selected > 0){
      setPagination({...pagination,initialPage:event.selected});
      await fetchData({page:(event.selected + 1)});
    }else{
      await fetchData();
    }
  };
  const activeMenu = (menu) =>{
    activeMenuOption.setCurrentmenu(menu);
  };
  return (
    <>
      <section className="content-header">
        <h1>Notification Listing</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-black">
              <div className="box-body no-padding">
                <div className="nav-tabs-custom no-margin">
                    
                  <ul className="nav nav-tabs">
                    <li className="active"><a href="javascript:void(0);">Listing</a></li>
                    <li className="pull-right">
                      <div className="box-tools">
                        <div className="input-group input-group-sm">
                          <Link className="btn btn-sm btn-primary" to="/notification/add" onClick={()=>activeMenu("notification_add")}>
                            <span className="glyphicon glyphicon-plus"></span>&nbsp;&nbsp;Send Notification
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
            
                  <div className="tab-content no-padding">
                    <div className="tab-pane active" id="listing">
                      <div className="table-responsive1">
                        <table className="table table-striped table-bordered TblAlignedCentered no-margin">
                          <thead>
                            <tr>
                              <th width="10%"></th>
                              <th width="5%">#</th>
                              <th></th>
                              <th width="15%">Send To</th>
                              <th width="10%">Status</th>
                              <th width="15%">Created</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pageLoad && 
                        <tr>
                          <td colSpan="6" className="text-center">
                            <i className="fa fa-spin fa-refresh"></i>
                          </td>
                        </tr>}
                            {!pageLoad && 
                        <>
                          {items.length>0  ?
                            items.map((item,key)=>{
                              return (
                                <tr key={key} className="products-list product-list-in-box">
                                  <td>
                                    <div className="input-group-btn">
                                      <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    Action &nbsp;&nbsp;
                                        <span className="fa fa-caret-down"></span>
                                      </button>
                                      <ul className="dropdown-menu">
                                        {!item.is_publish && 
                                    <li>
                                      <Link to={"/notification/edit/"+item.id} className="loader-overlay">
                                            Edit
                                      </Link>
                                    </li>}
                                        {item.is_publish ? 
                                          <li>
                                            <a href="javascript:void(0);" onClick={async()=>statusUpdate({id:item.id,created:item.created_at,status:item.is_activate})} className="loader-overlay">
                                              {item.is_activate?"Deactivate":"Activate"}
                                            </a>
                                          </li>:
                                          <li>
                                            <a href="javascript:void(0);" onClick={async()=>statusUpdate({id:item.id,created:item.created_at,publish:1,status:1})} className="loader-overlay">
                                              {"Publish"}
                                            </a>
                                          </li>}
                                        <li className="divider"></li>
                                        <li>
                                          <a href="javascript:void(0);" onClick={async()=>deleteItem({id:item.id,created:item.created_at})} className="loader-overlay">
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                  <td>{key+1}</td>
                                  <td>
                                    <div className="product-info no-margin">
                                      <a href="javascript:void(0)" className="product-title">{item.title}</a>
                                      <span className="product-description">{item.description}</span>
                                    </div>
                                  </td>
                                  <td>
                                    {item.sender.toLowerCase() == "system" ? (item.send_to == 1 ? "Associate Trainer" : "Associate Trainee") : ROLE[item.send_to]}
                                  </td>
                                  {item.is_publish ? 
                                    <td><span className={item.is_activate?"label label-success":"label label-danger"}>{item.is_activate?"Activate":"Deactivate"}</span></td>
                                    :
                                    <td><span className="label label-info">{"Draft"}</span></td>}
                                  <td>{getDate(item.created_at,true,false,"short")}<br/>{getDate(item.created_at,false,true)}</td>
                                </tr> );}) :
                            <tr>
                              <td colSpan="6" className="text-center">No Record found.</td>
                            </tr>
                          }
                        </>}
                          </tbody>
                        </table>
                      </div>            
                      <div className="box-footer clearfix">
                        <div className="text-center">
                          <div className="paginate-count pull-left">Total Records : {items.length}</div>
                        </div>
                        {pagination.pageCount > 1 && 
                    <ReactPaginate
                      previousLabel={"« previous"}
                      nextLabel={"next »"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pagination.pageCount}
                      forcePage={pagination.initialPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={async(event)=>await nextPageRecord(event)}
                      containerClassName={"pagination pagination-sm no-margin pull-right"}
                      subContainerClassName={""}
                      activeClassName={"active"}/>
                        }
                        {/* <ul className="pagination pagination-sm no-margin pull-right">
                        <li><a href="#">«</a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">»</a></li>
                    </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notification;