/* eslint-disable no-case-declarations */
import { REDUX,initialState } from "../../constants";
import { getState } from "../../localstorage";

const persistedState = getState();

const initState = persistedState.alertInfo ? persistedState.alertInfo : {alert:initialState.alert};
//console.log(initialState);
export function alertInfo(state = initState, action) {
  switch (action.type) {
  case REDUX.ALERT_INFO:
    return {
      alert: action.alert,
    };
  case REDUX.HIDE_ALERT:
    const info = initialState.alert;
    info.show = false;
    return {
      alert:info,
    };
  default:
    return state;
  }
}