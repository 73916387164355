import { useEffect } from "react";

const AjaxLoader = (props)=>{
  useEffect(()=>{
    //document.body.className += !props.isAuthenticated ? " login-page" : '';
  });
  return (
    <>
      {props.show && 
        <>
          <div className="pace pace-active">
            <div className="pace-progress" data-progress="10">
              <div className="pace-progress-inner"></div>
            </div>
            <div className="pace-activity"></div>
          </div>
          <div className="ajax-blocker"></div>
        </>}
    </>
  );	
};
export default AjaxLoader;