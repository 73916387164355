import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { activeMenuOption,pageLoadOption,callService } from "../../Utils";
import { API_URL } from "../../constants/api";

const initCounter = {trainee:0,trainer:0,category:0,session:0};
const Dashboard = ({match}) => {
  const [pageLoad, setPageLoad] = useState(true);
  const [counter,setCounter] = useState(initCounter);
  const { trainer,trainee,category,session } = counter;
  useEffect(async() => {
    pageLoadOption.load(true);
    //const trees = window.$('[data-widget="treeview"]');
    //window.$('[data-widget="treeview"]').Treeview('init');
    //trees.Treeview('init');
    /*<i className="fa fa-spin fa-refresh"></i>*/
    window.$("[data-widget=\"tree\"]").tree();
    activeMenuOption.setCurrentmenu("Dashboard","Dashboard");
    await fetchData();
  }, []);
  const fetchData = async() =>{
      
    let url = `${API_URL.DASHBOARD_COUNTER_API}`;
    const response = await callService(`${url}`,"GET");
    //console.log(response.data);
    if(typeof response !== undefined){
      if(response.success !== undefined && response.success){
        const {trainer,trainee,category,session} = response.data;
        setCounter({trainer,trainee,category,session});
      }
    }
    pageLoadOption.load(false);
  };
  return (
    <>
      <section className="content-header">
        <h1>
        Dashboard
          <small>Control panel</small>
        </h1>
        <ol className="breadcrumb">
          <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-aqua">
              <div className="inner">
                <h3>{trainer}</h3>
                <p>Total Trainer</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add"></i>
              </div>
              {/*<a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>*/}
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-green">
              <div className="inner">
                <h3>{trainee}</h3>

                <p>Total Trainee</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add"></i>
              </div>
              {/*<a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>*/}
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-yellow">
              <div className="inner">
                <h3>{session}</h3>

                <p>Upcoming Booking</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars"></i>
              </div>
              {/*<a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>*/}
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-red">
              <div className="inner">
                <h3>{category}</h3>
                <p>Total Categories</p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph"></i>
              </div>
              {/*<a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>*/}
            </div>
          </div>
        </div>
      </section>
    </>
  );

};

export default Dashboard;