// const API_BASE_URL = `http://ec2-13-58-21-73.us-east-2.compute.amazonaws.com:8080/api/v1/admin/`;
const API_BASE_URL = "https://api.roshico.com/api/v1/admin/";

export const API_URL = {
  LOGIN_API : `${API_BASE_URL}login`,
  CAT_LIST_API : `${API_BASE_URL}categories`,
  SUB_CAT_LIST_API : `${API_BASE_URL}categories/sub`,
  PARENT_CAT_LIST_API : `${API_BASE_URL}category/list`,
  CAT_ADD_API : `${API_BASE_URL}category/create`,
  CAT_EDIT : `${API_BASE_URL}category/`,
  CAT_UPDATE : `${API_BASE_URL}category/update/`,

  USER_LIST_API : `${API_BASE_URL}users/`,
  USER_UPDATE : `${API_BASE_URL}user/update/`,
  USER_API : `${API_BASE_URL}user/`,

  PROFILE_API : `${API_BASE_URL}profile/`,

  NOTIFICATION_LIST_API : `${API_BASE_URL}notifications/`,
  NOTIFICATION_ADD_API : `${API_BASE_URL}notification/create`,
  NOTIFICATION_UPDATE : `${API_BASE_URL}notification/update/`,
  NOTIFICATION_API : `${API_BASE_URL}notification/`,

  CHANGE_PASSWORD_API : `${API_BASE_URL}change_password/`,
  FILE_UPLOAD : `${API_BASE_URL}file-upload`,

  SESSION_API : `${API_BASE_URL}sessions/`,
  SETTING_API : `${API_BASE_URL}setting/`,
  SESSION_SCHEDULE_API : `${API_BASE_URL}schedule_sessions/`,

  DASHBOARD_COUNTER_API : `${API_BASE_URL}dashboard_counter/`,

  SESSION_CANCEL_API : `${API_BASE_URL}session_schedule_status/reject`,
};
