import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoggedUser } from "../../../redux/actions";
import { redirect,callService,alertOption,activeMenuOption,pageLoadOption } from "../../../Utils";
import { API_URL } from "../../../constants/api";
import { PAGES_TITLE } from "../../../constants";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const { email, password } = formData;
  const dispatch = useDispatch();

  useEffect(()=>{
    pageLoadOption.load(false);
    activeMenuOption.setPageTitle(PAGES_TITLE["login"]);
  });

  const handleChange = (e)=> {
    const { name, value } = e.target;
    setFormData(inputs => ({ ...inputs, [name]: value }));
  };

  const callApi = async(args) =>{
    //console.log(args,"test dmeo ");
    const response = await callService(API_URL.LOGIN_API,"POST",args);
    //console.log(response,"api call response ");
    if(response.success !== undefined && response.success){
      const {user,token,url} = response.data;
      user["token"] = token;
      user["image_path"] = url;
      //console.log(user);
      dispatch(setLoggedUser(user));
      redirect("/");
    }else{
      alertOption.setMsg({show:true,type:"danger",message: ( response.message !== undefined ? response.message : "There is a problem in your request." ) });
      setSubmitted(false);
    }
    // if(response.errors !== undefined && response.errors == 0){
    //   const res = await getProfileData({API_URL:params.API_URL,...response.response,redirectpage:response.other_data.redirectpage});
    //   if(!res.error){
    //     params.navigation.navigate(res.redirect);
    //   }else{
    //     obj.onChangeState('loading', false);
    //   }
    // }else if(response.errors){
    //   if(response.response.verified !== undefined && !response.response.verified){
    //     //showAlertMessage(MESSAGE.ERROR,response.response.message?response.response.message:response.msg);
    //     params.navigation.popToTop();
    //     params.navigation.navigate('Otp',{key:response.response.key,code:'',email:params.args.email,password:params.args.password,type:OTP_TYPE.REGISTER});
    //   }else{
    //     showAlertMessage(MESSAGE.ERROR,response.response.message?response.response.message:response.msg);
    //     obj.onChangeState('loading', false);
    //   }
    // }else{
    //   showAlertMessage(MESSAGE.ERROR);
    //   obj.onChangeState('loading', false);
    // }
  };

  const handleSubmit = async(e)=> {
    e.preventDefault();
    //dispatch(setAlertInfo({show:true,type:'danger',message:'ther is a problem in your request.'}));
    if (email && password && !submitted) {
      setSubmitted(true);
      await callApi({email, password});
      // get return url from location state or default to home page
      //const { from } = location.state || { from: { pathname: "/" } };
    }
  };

  return (
    <div className="login-box-body">
      <p className="login-box-msg">Sign in to start your session</p>
    
      <form method="post" onSubmit={handleSubmit}>
        <div className="form-group has-feedback">
          <input type="email" className="form-control" placeholder="Email" name="email" id="email" value={email} onChange={handleChange}/>
          <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
        </div>
        <div className="form-group has-feedback">
          <input type="password" className="form-control" placeholder="Password" name="password" id="password" value={password} onChange={handleChange}/>
          <span className="glyphicon glyphicon-lock form-control-feedback"></span>
        </div>
        <div className="row">
          <div className="col-xs-8">
          </div>
          <div className="col-xs-4">
            <button type="submit" className="btn btn-primary btn-block btn-flat">
              {submitted && (<>
                <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
              </>)}
                Sign In
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;