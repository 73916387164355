import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { capitalize,activeMenuOption,callService,pageLoadOption,alertOption,getDate,objectToQueryString,getDateWithFormat,getTime } from "../../Utils";
import { API_URL } from "../../constants/api";
import { PAGES_TITLE } from "../../constants";

import "react-datepicker/dist/react-datepicker.css";

const filterInit = {name:"",trainer:0,start:"",end:""};
const minStartDate = new Date();
minStartDate.setMonth(minStartDate.getMonth() - 1);

const Transactions = (props) => {
  const [pageLoad, setPageLoad] = useState(true);
  const [filter,setFilter] = useState(filterInit);
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [limit, setLimit] = useState(0);
  const { name,trainer,start,end } = filter;
    
    
  useEffect(async()=>{
    const params = new URLSearchParams(props.location.search);
    //console.log(props.location.search," query string ",params);
    let updateSearch = {name:params.get("name")?params.get("name"):"",
      trainer:(params.get("trainer")?params.get("trainer"):0),
      start:(params.get("start")?params.get("start"):""),
      end:(params.get("end")?params.get("end"):""),
    };
    setFilter(updateSearch);
    activeMenu("transactions");
    await fetchData(searchQueryString(updateSearch));
  },[]);

  const handleChange = (e)=> {
    const { name, value } = e.target;
    //console.log(name, value);
    setFilter(inputs => ({ ...inputs, [name]: value }));
  };
  const setDate = (name,value)=>{
    if(name=="start"){
      setFilter(inputs => ({ ...inputs, end: "" }));    
    }
    setFilter(inputs => ({ ...inputs, [name]: getDateWithFormat(value) }));
  };

  const resetSearch = async()=>{
    if(Object.keys(searchQueryString(filter)).length > 0){
      setFilter(filterInit);
      await fetchData();   
    }
  };

  const deleteItem = async({id,created},type=1)=>{
    let utype = type==1 ? "Trainer" : "Trainee" ;
    let msg = `Are you sure to delete the ${utype}?`;

    if(window.confirm(msg)){
      pageLoadOption.load(true);
      const response = await callService(`${API_URL.USER_API}${id}?create=${created}`,"DELETE");    
      if(response.success !== undefined && response.success){
        await fetchData(searchQueryString(filter));
        alertOption.setMsg({show:true,type:"success",message:response.message});
      }else{
        //set alert message info
        alertOption.setMsg({show:true,type:"error",message:response.message});
      }
      setFalseAll();
    }
  };

  const statusUpdate = async({id,created,status},type=0)=>{
    let msg = status ? "Are you sure to deactivate the item?" : "Are you sure to activate the item?";
    if(window.confirm(msg)){
      pageLoadOption.load(true);
      let url = `${API_URL.USER_UPDATE}${id}`;
      let method = "PATCH";
      let params = {created:created,status: status ? 0 : 1};
      const response = await callService(url,method,params);    
      if(response.success !== undefined && response.success){
        items.map(item=>{item.profile_status = !status; return item;});
        alertOption.setMsg({show:true,type:"success",message:response.message});
      }else{
        //set alert message info
        alertOption.setMsg({show:true,type:"error",message:response.message});
      }
      setFalseAll();
    }
  };
  const setFalseAll = ()=>{
    setPageLoad(false);
    pageLoadOption.load(false);
  };
  const fetchData = async(queryString = {}) =>{
    pageLoadOption.load(true);
    //console.log(`${API_URL.USER_LIST_API}${match.params.type}`);
    let url = `${API_URL.SESSION_API}`;
    let qryString = {};
    if(Object.keys(queryString).length>0){
      qryString = objectToQueryString(queryString);
      url += "?"+qryString;
    }
    updateHistory(qryString);
        
    const response = await callService(`${url}`,"GET");
    if(response.success !== undefined && response.success){
      const {items,trainerList,totalRecord,Count,limit} = response.data;
      setItems(items);
      setLimit(limit);
      setUsers(trainerList);
      setTotalRecord(totalRecord);
      setRecord(Count);
      setPagination(response.data.pagination);
    }
    setFalseAll();
  };
  const activeMenu = (menu) =>{
    activeMenuOption.setCurrentmenu(menu,PAGES_TITLE[menu]);
  };
    
  const paginate = (pageno)=>{
    if(currentPage != pageno){
      props.history.push({
        pathname: "/sessions",
        search: pageno > 1 ? "?page=" + pageno : "",
      });
      setCurrentPage(pageno);
    }
  };
  const updateHistory = (queryString)=>{
    props.history.push({
      pathname: "/transactions",
      search: Object.keys(queryString).length>0?"?" + queryString:"",
    });
  };
  const searchQueryString = (searchFilter)=>{
    let queryString = {};
        
    if(searchFilter.name != ""){
      queryString["name"] = searchFilter.name;
    }
    if(searchFilter.trainer > 0){
      queryString["trainer"] = searchFilter.trainer;
    }
    if(searchFilter.start != ""){
      queryString["start"] = searchFilter.start;
    }
    if(searchFilter.end != ""){
      queryString["end"] = searchFilter.end;
    }

    return queryString;
  };
  const handlerSearch = async(e)=> {
    e.preventDefault();
    let queryString = {};
    queryString = searchQueryString(filter);
    if (Object.keys(queryString).length > 0) {
      await fetchData(queryString);
    }
  };

  const setMaxDate = ()=>{
    if(start){
      let current = new Date(start);
      current.setMonth(current.getMonth()+3);
      return current;
    }
    return "";
  };

  return (
    <>
      <section className="content-header">
        <h1>Manage Sessions</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-black">
              <div className="box-header with-border">

                <form onSubmit={handlerSearch}>
                  <div className="pull-left">&nbsp;&nbsp;</div>
                  <div className="input-group input-group-sm pull-left">
                    <div className="input text">
                      <input type="text" name="name" className="form-control pull-right search" placeholder="Search by Session Name" value={name} onChange={(e)=>handleChange(e)} id="name"/>
                    </div>
                  </div>
                  <div className="pull-left">&nbsp;&nbsp;</div>
                  <div className="input-group input-group-sm pull-left">
                    <div className="input select">
                      <select value={trainer} name="trainer" className="form-control pull-right search select2" tabIndex="-1" aria-hidden="true" onChange={(e)=>handleChange(e)}>
                        <option value="">Select Trainer</option>
                        {Object.keys(users).map((key)=><option key={key} value={key}>{users[key]}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="pull-left">&nbsp;&nbsp;</div>
                  <div className="input-group input-group-sm pull-left">
                    <div className="input select">
                      <DatePicker selected={start?new Date(start):""} onChange={(date) => setDate("start",date)}  className="form-control pull-right search" placeholderText="Start Date" dateFormat="yyyy-MM-dd"/>
                    </div>
                  </div>
                  <div className="pull-left">&nbsp;&nbsp;</div>
                  <div className="input-group input-group-sm pull-left">
                    <DatePicker selected={end?new Date(end):""} onChange={(date) => setDate("end",date)}  className="form-control pull-right search" minDate={start?new Date(start):""} maxDate={setMaxDate()} placeholderText="End Date" dateFormat="yyyy-MM-dd"/>
                  </div>
                  <div className="pull-left">&nbsp;&nbsp;</div>
                  <div className="input-group input-group-sm pull-left">
                    <button className="btn btn-default">
                      <i className="fa fa-search"></i>
                    </button>&nbsp;
                    <a href="javascript:void(0)" onClick={()=> resetSearch()} className="btn btn-primary loader-overlay">Reset</a>
                  </div>
                </form>        
              </div>
              <div className="box-body">
                <div className="table-responsive1">
                  <table className="table table-striped table-bordered TblAlignedCentered no-margin">
                    <thead>
                      <tr>
                        {/*<th width="12%"></th>*/}
                        <th width="12%">#</th>
                        <th>Name</th>
                        <th>Trainer</th>
                        <th>Start Date</th>
                        <th>Time</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    {pageLoad && 
                <tbody>
                  <tr>
                    <td colSpan="6" className="text-center">
                      <i className="fa fa-spin fa-refresh"></i>
                    </td>
                  </tr>
                </tbody>}
                    {!pageLoad && <tbody>
                      {items.length>0  ?
                        items.map((item,key)=>{
                          return (
                            <tr key={key}>
                              {/*<td>
                                                    <div className="input-group-btn">
                                                        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                            Action &nbsp;&nbsp;
                                                            <span className="fa fa-caret-down"></span>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a href="javascript:void(0);" className="loader-overlay">
                                                                    {item.profile_status?`Deactivate`:`Activate`}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <Link to={"/user/"+item.id} className="loader-overlay">
                                                                    View
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>*/}
                              <td>{key+1}</td>
                              <td>{item.session_name}</td>
                              <td>{Object.keys(users).length > 0 ? users[item.user_id] : ""}</td>
                              <td>{getDate(parseInt(item.session_date),true,false)}</td>
                              <td>{getTime(parseInt(item.start_time))} - {getTime(parseInt(item.end_time))}</td>
                              <td>{getDate(parseInt(item.created_at))}</td>
                            </tr> );
                        }) :
                        <tr>
                          <td colSpan="6" className="text-center">No Record found.</td>
                        </tr>
                      }
                    </tbody>}
                  </table>
                </div>
              </div>
              <div className="box-footer clearfix">
                <div className="text-center">
                  <div className="paginate-count pull-left">Total Records : {items.length}</div>
                </div>
                {Object.keys(pagination).length > 0 &&
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalRecord}
                      pageRangeDisplayed={parseInt(totalRecord)/parseInt(limit)}
                      onChange={(pageNumber)=>paginate(pageNumber)}
                      innerClass="pagination pagination-sm no-margin pull-right"
                    />}
                {/*Object.keys(pagination).length > 0 &&
                <ul className="pagination pagination-sm no-margin pull-right">
                    <li><a href="javascript:void(0);" onClick={()=>paginate(1)}>«</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(1)}>1</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(2)}>2</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(3)}>3</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(3)}>»</a></li>
                </ul>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transactions;