import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { redirect,callService,activeMenuOption,alertOption } from "../../Utils";
import { API_URL } from "../../constants/api";
import { PAGES_TITLE } from "../../constants";

const initialState = {
  old_password: "",
  password: "",
  confirm_password: "",
};

const ChangePassword = () => {
  const [formData, setFormData] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({old_password:false,password:false,confirm_password:false});
  const [errorMsg, setErrorMsg] = useState({old_password:"This Field is mandatory",password:"This Field is mandatory",confirm_password:"This Field is mandatory"});
  const { old_password, password, confirm_password } = formData;
  const dispatch = useDispatch();

  useEffect(() => {
    activeMenuOption.setCurrentmenu("change_password",PAGES_TITLE["change_password"]);
  }, []);

  const handleChange = (e)=> {
    const { name, value } = e.target;
    setFormData(inputs => ({ ...inputs, [name]: value }));
  };

  const callApi = async(args) =>{
    const response = await callService(API_URL.CHANGE_PASSWORD_API,"POST",{old_password, password});
    let alertParam = {show:true,message:response.message};
    if(response.success !== undefined && response.success){
      alertParam["type"] = "success";
      setFormData(initialState);
    }else{
      alertParam["type"] = "danger";
    }
    alertOption.setMsg(alertParam);
    setSubmitted(false);
  };
  const setFormError = (name,value)=> {
    setErrors(inputs => ({ ...inputs, [name]: value }));
  };
  const setFormErrorMsg = (name,value)=> {
    setErrorMsg(inputs => ({ ...inputs, [name]: value }));
  };
  const validateFrm = ()=> {
    let err = false;
    if(old_password==""){
      setFormError("old_password",true);
      err = true;
    }
    if(password==""){
      setFormError("password",true);
      err = true;
    }
    if(confirm_password==""){
      setFormError("confirm_password",true);
      err = true;
    }
    if(confirm_password && password && confirm_password!=password){
      setFormError("confirm_password",true);
      setFormErrorMsg("confirm_password","Confirm password should be match with password.");
      err = true;
    }
    return !err;
  };

  const handleSubmit = async(e)=> {
    e.preventDefault();
        
    if (validateFrm()) {
      setSubmitted(true);
      await callApi();
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Change Password</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Update Password</h3>
              </div>
              <div className="box-body">
                <div className="x_content">
                  <form method="post" className="form-horizontal form-label-left" onSubmit={handleSubmit}>
                    <div className="box-body">
                      <div className="input password required">
                        <div className={errors.old_password ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="old-password">Old Password</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="password" name="old_password" value={old_password} placeholder="Old Password" required="required" className="form-control col-md-7 col-xs-12" id="old-password" onChange={handleChange}/>
                            {errors.old_password && <span className="help-block">{errorMsg.old_password}.</span>}
                          </div>
                        </div>
                      </div>
                      <div className="input password required">
                        <div className={errors.password ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="password">Password</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="password" name="password" value={password} placeholder="New password" required="required" className="form-control col-md-7 col-xs-12" id="password" onChange={handleChange}/>
                            {errors.password && <span className="help-block">{errorMsg.password}.</span>}
                          </div>
                        </div>
                      </div>
                      <div className="input password required">
                        <div className={errors.confirm_password ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="confirm-password">Confirm Password</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="password" name="confirm_password" value={confirm_password} placeholder="Confirm New password" required="required" className="form-control col-md-7 col-xs-12" id="confirm-password" onChange={handleChange}/>
                            {errors.confirm_password && <span className="help-block">{errorMsg.confirm_password}.</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-footer">
                      <div className="form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12 col-md-offset-3">
                          <Link to="/dashboard">Cancel</Link>&nbsp;&nbsp;
                          <button className="btn btn-primary" type="submit">{submitted && (<>
                            <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                          </>)}Save</button>
                        </div>
                      </div>
                    </div>
                  </form>   
                </div> 
              </div>                   
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;