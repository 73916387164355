/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { activeMenuOption,callService,pageLoadOption,alertOption,redirect } from "../../Utils";
import { API_URL } from "../../constants/api";
import { ROLE,PAGES_TITLE } from "../../constants";

const initialState = {
  title: "",
  description: "",
  send_to: 3,
};

const Add = (props) => {
  const edit = (props.match.params.id ? true : false );
  const [created, setCreated] = useState("");
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState({title:false,description:false,send_to:false});
  const [msg, setMsg] = useState({title:"",description:"",send_to:""});
    
  const [submitted, setSubmitted] = useState(false);
  const { title, description, send_to } = inputs;
    
  useEffect(async()=>{
    if(edit){
      await getItemData();
      activeMenuOption.setPageTitle(PAGES_TITLE["notification_edit"]);
    }else{
      activeMenuOption.setPageTitle(PAGES_TITLE["notification_add"]);
    }
  },[]);
    
  const getItemData = async()=>{
    pageLoadOption.load(true);
    const response = await callService(`${API_URL.NOTIFICATION_API}${props.match.params.id}`,"GET");
    if(response.success !== undefined && response.success){
      const { item } = response.data;
      setInputs({title:item.title,description:item.description,send_to:item.send_to});
      setCreated(item.created_at);
    }
    pageLoadOption.load(false);
  };
  const activeMenu = (menu) =>{
    activeMenuOption.setCurrentmenu(menu);
  };
  const handleChange = (e)=> {
    const { name, value } = e.target;
    //console.log(name, value);
    setInputs(inputs => ({ ...inputs, [name]: value }));
    if(value!=""){
      setFormError(name,false);
    }
  };
  const setFormError = (name,value)=> {
    //console.log(name, value);
    setErrors(inputs => ({ ...inputs, [name]: value }));
  };
    
  const validateFrm = ()=> {
    let err = false;
    if(title==""){
      setFormError("title",true);
      err = true;
    }
    if(description==""){
      setFormError("description",true);
      err = true;
    }
    if(send_to==""){
      setFormError("send_to",true);
      err = true;
    }
    return !err;
  };
  const handleSubmit = async(e)=> {
    e.preventDefault();
        
    if (validateFrm()) {
      setSubmitted(true);
      await addItem();
    }
  };
  const addItem = async() =>{
    let params = {title,description,send_to};
    // if(subCat || parent_id>0){
    //     params['parent_id'] = parent_id;
    // }
    //name, description, image, parent_id
    let url = API_URL.NOTIFICATION_ADD_API;
    let method = "POST";
    if(edit){
      url = `${API_URL.NOTIFICATION_UPDATE}${props.match.params.id}`;
      method = "PATCH";
      params["created"] = created;
    }
    const response = await callService(url,method,params);
    //console.log(response,"api call response ");
    if(response.success !== undefined && response.success){
      setInputs(initialState);
      //page load
      pageLoadOption.load(true);
      //set alert message info
      alertOption.setMsg({show:true,type:"success",message:response.message});
      //set Current Menu 
      activeMenu("notification");
      //redirect on listing page
      redirect("/notification",props.history);
    }else{
      alertOption.setMsg({show:true,type:"danger",message:response.message});
      setSubmitted(false);
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Send Notification</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title"></h3>
                <div className="box-tools">
                  <div className="input-group input-group-sm">
                    <Link to="/notification" onClick={()=>activeMenu("notification")} className="btn btn-sm btn-primary">
                      <span className="fa fa-backward"></span>&nbsp;&nbsp;Back To Listing
                    </Link>
                  </div>
                </div>
              </div>
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="box-body">
                  <div className={errors.parent_id ? "form-group has-error":"form-group"}>
                    <label htmlFor="parent_id" className="col-sm-2 control-label">Notify To</label>
                    <div className="col-sm-7">
                      <select name="send_to" value={send_to} className="form-control is-invalid" id="send_to" onChange={handleChange}>
                        {Object.keys(ROLE).map((id, index)=><option value={id}>{ROLE[id]}</option>)}
                      </select>
                      {errors.send_to && <span className="help-block">This field is mandatory.</span>}
                    </div>
                    {/*<span className="help-block d-block col-sm-offset-2 col-sm-10">Help block with error</span>*/}
                  </div>
                  <div className={errors.title ? "form-group has-error":"form-group"}>
                    <label htmlFor="title" className="col-sm-2 control-label">Title</label>
                    <div className="col-sm-7">
                      <input type="text" className="form-control is-invalid" name="title" id="title" placeholder="Title" value={title} onChange={handleChange}/>
                      {errors.title && <span className="help-block">This field is mandatory.</span>}
                    </div>
                    {/*<span className="help-block d-block col-sm-offset-2 col-sm-10">Help block with error</span>*/}
                  </div>
                  <div className={errors.description ? "form-group has-error":"form-group"}>
                    <label htmlFor="description" className="col-sm-2 control-label">Description</label>
                    <div className="col-sm-7">
                      <textarea className="form-control" name="description" value={description} id="description" placeholder="Description" onChange={handleChange}>{description}</textarea>
                      {errors.description && <span className="help-block">This field is mandatory.</span>}
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        {submitted && (<>
                          <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                        </>)}
                        {edit ? "Update":"Send as Draft"}
                      </button>&nbsp;&nbsp;  
                      <Link to="/notification" onClick={()=>activeMenu("notification")} className="btn btn-default">Cancel</Link>    
                    </div>
                  </div>
                </div>
              </form>                    
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default (Add);